

















































































































































































































































import Vue from 'vue';
import * as Api from '@/api/order';
import { downloadFile } from '@/utils/index';
import { message, Modal } from 'ant-design-vue';
import moment from 'moment';
const columns = [
  {
    title: '订单编号',
    dataIndex: 'orderSn',
    align: 'center',
    fixed: 'left',
  },
  {
    title: '产品名称',
    dataIndex: 'spuName',
    align: 'left',
  },
  {
    title: '订购租户',
    dataIndex: 'agencyName',
    align: 'left',
  },
  {
    title: '订购账号',
    dataIndex: 'userAccount',
    align: 'center',
  },
  {
    title: '租户类型',
    dataIndex: 'agencyType',
    align: 'center',
    scopedSlots: { customRender: 'agencyType' },
  },
  {
    title: '应付金额',
    dataIndex: 'totalPrice',
    align: 'center',
    scopedSlots: { customRender: 'totalPrice' },
  },
  {
    title: '优惠金额',
    dataIndex: 'discountPrice',
    align: 'center',
    scopedSlots: { customRender: 'discountPrice' },
  },
  {
    title: '劵类型',
    dataIndex: 'discountType',
    align: 'center',
    scopedSlots: { customRender: 'discountType' },
  },
  {
    title: '劵名称',
    dataIndex: 'discountName',
    align: 'center',
    scopedSlots: { customRender: 'discountName' },
  },
  {
    title: '实付金额',
    dataIndex: 'payPrice',
    align: 'center',
    scopedSlots: { customRender: 'payPrice' },
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    align: 'center',
    scopedSlots: { customRender: 'payType' },
  },
  {
    title: '实物名称',
    dataIndex: 'gift',
    align: 'center',
    scopedSlots: { customRender: 'gift' },
  },
  {
    title: '收货人',
    dataIndex: 'name',
    align: 'center',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '电话号码',
    dataIndex: 'mobile',
    align: 'center',
    scopedSlots: { customRender: 'mobile' },
  },
  {
    title: '收货地址',
    dataIndex: 'address',
    align: 'left',
    scopedSlots: { customRender: 'address' },
  },
  {
    title: '下单时间',
    dataIndex: 'recDate',
    align: 'center',
    scopedSlots: { customRender: 'recDate' },
  },
  {
    title: '支付时间',
    dataIndex: 'payTime',
    align: 'center',
    scopedSlots: { customRender: 'payTime' },
  },
  {
    title: '状态',
    dataIndex: 'paid',
    align: 'center',
    fixed: 'right',
    scopedSlots: { customRender: 'paid' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    align: 'center',
    fixed: 'right',
    scopedSlots: { customRender: 'action' },
  },
];
const tableData = [];
export default Vue.extend({
  name: 'orderList',
  data() {
    return {
      tableLoading: false,
      exportLoading: false,
      exportOrderListLoading: false,
      showCompensateDetail: false,
      compensateDetailSpinning: false,
      compensateDetail: {
        compensatePerson: '', // 补单人
        compensateTime: undefined, //补单时间
      },
      formState: {
        paid: '',
        orderSn: '',
        agencyName: '',
        agencyType: '',
      },
      tableData,
      columns,
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      applyTime: ['', ''],
      payTime: ['', ''],
    };
  },
  mounted() {
    this.getOrderPageList();
  },
  methods: {
    moment,
    /**
     * @description: 查询按钮
     * @param {*}
     * @return {*}
     */
    getOrderPageList() {
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        paid: this.formState.paid,
        orderSn: this.formState.orderSn,
        agencyName: this.formState.agencyName,
        agencyType: this.formState.agencyType,
        orderStartTime: this.applyTime[0],
        orderEndTime: this.applyTime[1],
        payStartTime: this.payTime[0],
        payEndTime: this.payTime[1],
      };
      this.tableLoading = true;
      Api.orderPage(param)
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    /**
     * @description: 分页
     * @param {*}
     * @return {*}
     */
    handleChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getOrderPageList();
    },
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getOrderPageList();
    },
    handleExportGoods() {
      console.log('handleExportGoods');
      console.log('payTime:', this.payTime);
      const payStartTime = this.payTime[0];
      const payEndTime = this.payTime[1];
      this.exportLoading = true;
      Api.goodsExport(payStartTime, payEndTime)
        .then((res: any) => {
          downloadFile(res, '实物清单');
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    // 订购管理列表导出
    handleExportOrderList() {
      this.exportOrderListLoading = true;
      Api.orderListExport(
        this.formState.paid,
        this.formState.agencyType,
        this.formState.orderSn,
        this.formState.agencyName,
        this.applyTime[0],
        this.applyTime[1],
        this.payTime[0],
        this.payTime[1]
      )
        .then((res: any) => {
          downloadFile(res, '列表数据');
        })
        .finally(() => {
          this.exportOrderListLoading = false;
        });
    },
    handleCompensate(record) {
      Modal.confirm({
        title: '确定进行手动补单吗？',
        content:
          '手动补单一般用于用户已完成支付，但所订购产品资源因过期时间已到而自动取消！',
        onOk: () => {
          return new Promise((resolve) => {
            const param = {
              orderNo: record.orderSn,
            };
            Api.manualCompensation(param)
              .then((res) => {
                if (res.result) {
                  message.success('补单成功！');
                } else {
                  message.error('补单失败，失败原因：该订单未支付。');
                }
              })
              .finally(() => {
                resolve(true);
                this.handleSearchSubmit();
              });
          });
        },
      });
    },
    handleCompensateDetail(record) {
      this.showCompensateDetail = true;
      this.compensateDetailSpinning = true;
      Api.getCompensateDetail(record.sequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.compensateDetail.compensatePerson = res.result.userName;
            this.compensateDetail.compensateTime = res.result.recDate;
          }
        })
        .finally(() => {
          this.compensateDetailSpinning = false;
        });
    },
  },
});
